

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './img/logo.svg'
import exit from './img/exit.svg'
import Profile from './img/Profile.svg'
// import bg from './img/bg.svg'
import biglogo from './img/biglogo.svg'
import { ImpulseSpinner } from "react-spinners-kit";
import {

    Navbar,
    Container,
    Nav,
    NavDropdown

} from 'react-bootstrap';
import { Link } from 'react-router-dom';
class Create extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
       email:"",
       pass: "",
       text : "",
       preload: false,
       main : false
               }
               this.name = this.name.bind(this);
               this.email = this.email.bind(this);
               this.pass = this.pass.bind(this);
               this.create = this.create.bind(this);
               this.clear = this.clear.bind(this);
               

    }

    clear(){
this.setState({
    email: "",
    pass: ""
})
    }
    name(e){
// console.log(e.target.value)
this.setState({
    name:e.target.value
})
    }
    email(e){
        this.setState({
            email:e.target.value
        })
        console.log(e.target.value)
    }
    pass(e){
        this.setState({
            pass:e.target.value
        })
        // console.log(e.target.value)
    }

    componentDidMount() {
       


        if(localStorage.getItem('token')){
            if(localStorage.getItem('token').length > 0 ){
                window.location.href = "/"
            }else{
      
            }
      
          }else{
           
          if(window.location.pathname == "/"){
            
         
          }else{
            // document.location.replace("/auth");
          }
      
          }
    }
    back (){
        window.location.href = "/auth";
    }
    create(){
        this.setState({
            preload: true
        })
        const main ={
            "email": this.state.email,
            "password": this.state.pass
        }
        console.log(main)
        fetch('https://mwapi.proc.one/api/create_acc', {
            method: 'POST',


            headers: {
               
                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
           
            },
            body: JSON.stringify({

                "email": this.state.email,
                "password": this.state.pass
             
            })

        })


            .then(res => res.json())

            .then((data) => {
                    console.log(data)
                    this.setState({
                        preload: false
                    })
if(data.error == 0){
    this.setState({
        text: data.reason,
        main: true
    })
}else{
    this.setState({
        text: data.reason,
        main: true
    })
}

                }
            )
    }

    render() {
        const {main} = this.state
     return(
 <div  className={""}>
            {(() => {

if (this.state.preload == false) {
  
} else {
    return (
        <div className={"preloaders"}>
        <ImpulseSpinner className={"preloaders "} size={40} frontColor="black" backColor={"black"} />  
          </div>
    )
}

})()}
<div className={"au_main"}>

<a href={"/"}>    
<img src={biglogo}></img></a>
<p className={"welcomess"}>Create acount</p>
<p className={"contin"}>Use your valid email for registration</p>

<div className={"inl"}>
<input placeholder={"Email"} value={this.state.email} onChange={this.email}></input>
</div>
<div className={"inl"}>
<input placeholder={"Password "} type={"password"}  value={this.state.pass} onChange={this.pass}></input>
</div>
{(() => {  
    if(main == false){

    }else{
        return(
            <p>
                {this.state.text}
            </p>
        )
    }
     })()}
<div className={"rega_blo"}>
<button className={"reg_l"} onClick={this.create}>Sign Up</button>
<a onClick={this.clear}  className={"reg_r"}>Clear </a>
</div>


<a className={"forgot"} href={"#"} onClick={this.back}>Back to login</a>
</div>
<div className={"absol_main"}>

{/* <img className={"bg_svet"} data={bg} src={bg}></img> */}
</div>
 </div>
     )  
  }
}
export default Create;