import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import bg from './img/bg.svg'
import def from './img/def.svg'
import logo from './logo.svg';
import clear from './img/clear.svg'
import clear1 from './img/clear1.svg'
import save from './img/save.svg'
import save1 from './img/save1.svg'
// import London from './img/london.svg';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import cash from './img/cash.svg'
import home from './img/home.svg'
import print from './img/print.svg'
import repeat from './img/repeat.svg'

// import silc from './img/silc.svg'


import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Circle } from 'react-preloaders';
import { ImpulseSpinner, TraceSpinner } from "react-spinners-kit";
import InputMask from "react-input-mask";
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            errostext: "",
            maxa: 4,
            textprodec: "",
            mask: "+# ##   ",
            proc_id: "",
            dimas: "",
            dinaminat: "",
            productlogo: '',
            teeerr: "",
            city: "",
            phone: "",
            allCat: "",
            main: "",
            PayLoad: false,
            txt: "Select the country",
            cates: "",
            id: "",
            isLoaded: false,
            number: "",
            opersurl: "",
            drop: false,
            def: def,
            monet: "",
            preload: false,
            contid: "",
            allcatar: "",
            trans: "",
            mobload: false,
            Loader: false,
            mainLoaders: false,
            dinaminat: "",
            nata: "Please choose the country from below list or enter the phone number to make the payment",
            mainL: "",
            disLoad: false,
            mymask: "9999999999999999999",
            mainLoad: false
        }
        // this.onChange = this.onChange.bind(this);
        this.buy = this.buy.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.trackClick = this.trackClick.bind(this);
        this.canera = this.canera.bind(this);
        this.dia = this.dia.bind(this);
        this.repeat = this.repeat.bind(this);
        this.clear = this.clear.bind(this);
        this.save = this.save.bind(this);
        this.home = this.home.bind(this);
        this.onPaste = this.onPaste.bind(this);
        this.clear = this.clear.bind(this);
        this.handleOnChangePhoneInput = this.handleOnChangePhoneInput.bind(this);

        this.print = this.print.bind(this);
    }
    repeat() {
        this.setState({
            PayLoad: false
        })
    }
    print(zpl) {
        var printWindow = window.open();
        printWindow.document.open('text/plain')

        printWindow.document.write("fsdfjsjdflkdsj sdkfjlkds dsfklj dslk dsfsdf");
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }
    home() {
        window.location.href = "/"
    }
    clear() {
        localStorage.removeItem('data')
        this.setState({
            Loadpay: false,
            city: "",
            lineload: false,
            textprodec: "",
            productlogo: '',
            dinaminat: "",
            // Loda: false,
            disLoad: false,
            cates: "",
            phone: ''
            // mainLoaders: false
        })

        fetch('https://mwapi.proc.one/api/content/get_country_by_prefix?prefix=', {
            method: 'GET',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token': localStorage.getItem('token')
            },


        })


            .then(res => res.json())

            .then((data) => {
                console.log(data)



                data.countries_list.sort((a, b) => {
                    return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
                })
                console.log(data.countries_list)
                this.setState({
                    drop: false,
                    canad: data,


                })
            })
        fetch('https://mwapi.proc.one/api/payment/international?number=' + this.state.phone, {
            method: 'GET',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token': localStorage.getItem('token')
            },


        })


            .then(res => res.json())

            .then((data) => {
                console.log(data)
                this.setState({
                    preload: false,
                    errostext: ""
                })
                if (data.error == 0) {


                    this.setState({
                        allcatar: data.all_categories,
                        // city: "",
                        isLoaded: true,
                    })
                }


            }
            )


        fetch('https://mwapi.proc.one/api/content/get_all_categories', {
            method: 'GET',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token': localStorage.getItem('token')
            },


        })


            .then(res => res.json())

            .then((data) => {
                console.log(data)

                if (data.error == 0) {


                    this.setState({
                        contid: "",
                        txt: "Select the country",
                        phone: "",
                        def: def,
                        nata: "Please choose the country from below list or enter the phone number to make the payment",
                        contid: "",
                        monet: data.all_products_list,
                        // cates: "",
                        Loader: true,
                        Loda: false,
                        cates: ""
                    })









                }

            }
            )


    }
    save = e => {

        e.preventDefault();
        this.setState({
            preload: true,
            main: "",
            opersurl: "",
            productlogo: '',
            disLoad: false
        })
        console.log(this.state.phone)
        fetch('https://mwapi.proc.one/api/payment/international?number=' + this.state.phone, {
            method: 'GET',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token': localStorage.getItem('token')
            },


        })


            .then(res => res.json())

            .then((data) => {
                console.log(data)
                this.setState({
                    preload: false,
                    errostext: ""
                })
                if (data.error == 0) {
                    if (data.denominations.length >= 1) {
                        console.log(data)
                        this.setState({
                            mobload: true,
                            allCat: data.all_categories,
                            dinaminat: data.denominations,
                            country: data.country,
                            mainLoad: true,
                            textprodec: false,
                            Loda: false,
                            cates: "Mobile",
                            operator: data.operator,
                            Loader: false,
                            trans: data,
                            number: data.number,
                            opersurl: data.operator_img,
                            currency: data.currency,
                            allcatar: data.all_categories,
                            Loadpay: true,

                        })
                    } else if (this.state.phone.length == 0) {
                        this.setState({
                            allcatar: data.all_categories,
                            city: "",
                        })
                    }

                    else if (data.dtOne.result > 0) {
                        if (this.state.phone.length == 0) {
                            this.setState({
                                errostext: ""
                            })
                        } else {
                            this.setState({
                                errostext: data.dtOne.desc
                            })
                        }
                        console.log("0000000000000000")

                    }
                } else if (data.error == 403) {
                    localStorage.removeItem('token');
                    window.location.href = "/auth"
                }


            }
            )
    }
    canera(obj) {
        console.log(obj)
        this.setState({
            phone: obj.mask,
            pref: obj.mask,
            txt: obj.txt,
            productlogo: '',
            id: "",
            disLoad: false,
            nata: "Enter the phone number to pay or choose the service below"
        })

        fetch('https://mwapi.proc.one/api/content/get_country_by_prefix?prefix=' + obj.mask, {
            method: 'GET',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token': localStorage.getItem('token')
            },


        })


            .then(res => res.json())

            .then((data) => {
                console.log(data)

                if (data.countries_list.length == 1) {
                    localStorage.setItem('data', data.countries_list[0].phonePrefix)
                    this.setState({
                        drop: false,
                        canad: data,
                        city: data.countries_list[0].country_city_img,
                        min: data.countries_list[0].minAccLen,
                        def: data.countries_list[0].country_img_url,
                        contid: data.countries_list[0].id,

                    })






                    fetch('https://mwapi.proc.one/api/content/get_all_categories?country_id=' + data.countries_list[0].id, {
                        method: 'GET',


                        headers: {

                            'Content-Type': 'application/json',
                            'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                            'token': localStorage.getItem('token')
                        },


                    })


                        .then(res => res.json())

                        .then((data) => {
                            console.log(data)
                            this.setState({
                                allcatar: data.all_categories,
                                mainLoaders: true
                            })

                        })



                    fetch('https://mwapi.proc.one/api/content/get_products?category_id&country_id=' + data.countries_list[0].id, {
                        method: 'GET',


                        headers: {

                            'Content-Type': 'application/json',
                            'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                            'token': localStorage.getItem('token')
                        },


                    })


                        .then(res => res.json())

                        .then((data) => {
                            console.log(data)
                            this.setState({
                                monet: data.all_products_list,
                                // Loda: true,
                                Loader: true,
                            })

                        }
                        )

                } else if (data.countries_list.length == 0) {
                    this.setState({
                        drop: false,

                    })
                } else {
                    this.setState({
                        canad: data,
                        drop: true,
                    })
                }
            }
            )

    }
    handleChange(e) {

        var originalString = e.target.value
        var test1 = "+998 xxx xxx xxx"
        var phnum = originalString.replace(/[^+\d]/g, '')
        console.log(e.target.value)
        console.log(phnum)
        console.log(test1.replace(/[^+\d]/g, ''))
        this.setState({
            mail: e.target.value,

        })

        // console.log(newString.trim())


        fetch('https://mwapi.proc.one/api/content/get_country_by_prefix?prefix=' + phnum, {
            method: 'GET',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token': localStorage.getItem('token')
            },


        })


            .then(res => res.json())

            .then((data) => {
                console.log(data)

                if (data.countries_list.length == 1) {

                    this.setState({
                        drop: false,
                        canad: data,
                        pref: data.countries_list[0].phonePrefix
                    })

                } else if (data.countries_list.length == 0) {
                    this.setState({
                        drop: false,

                    })
                } else {
                    this.setState({
                        canad: data
                    })
                }
            }
            )


    }

    dia(obj) {
        console.log(obj)
        this.setState({
            textprodec: obj.textprodec,
            proc_id: obj.id,
            productlogo: '',
            id: "",
            PayLoad: false,
            productlogo: obj.productlogo
        })

        this.setState({
            disLoad: false
        })
        fetch('https://mwapi.proc.one/api/customer/payment/get_product_denominations?product_id=' + obj.id, {
            method: 'GET',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token': localStorage.getItem('token')
            },


        })


            .then(res => res.json())

            .then((data) => {
                console.log(data)
                if (data.error == 1) {

                } else {
                    data.denominations_list.sort(function (a, b) {
                        if (a.denomination > b.denomination) {
                            return 1;
                        }
                        if (a.denomination < b.denomination) {
                            return -1;
                        }
                        return 0;
                    });
                    setTimeout(() => {
                        this.setState({ disLoad: true, })
                    }, 500);
                    this.setState({
                        dimas: data.denominations_list,
                        lineload: true,
                        Loadpay: true
                    })
                }

            }
            )




    }

    componentDidMount() {
        document.addEventListener('click', this.trackClick);



        if (localStorage.getItem('data')) {


            fetch('https://mwapi.proc.one/api/content/get_all_categories', {
                method: 'GET',


                headers: {

                    'Content-Type': 'application/json',
                    'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                    'token': localStorage.getItem('token')
                },


            })


                .then(res => res.json())

                .then((data) => {
                    console.log(data)

                    if (data.error == 0) {


                        this.setState({
                            monet: data.all_products_list,
                            // cates: "",
                            mainLoaders: true,
                            Loader: true,

                        })

                        fetch('https://mwapi.proc.one/api/content/get_all_countries', {
                            method: 'GET',


                            headers: {

                                'Content-Type': 'application/json',
                                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                                'token': localStorage.getItem('token')
                            },


                        })


                            .then(res => res.json())

                            .then((data1) => {
                                console.log(data1)

                                if (data1.error == 0) {

                                    this.setState({
                                        isLoaded: true,
                                        canad: data1,
                                        allcatar: data.all_categories,

                                    })





                                    fetch('https://mwapi.proc.one/api/content/get_country_by_prefix?prefix=' + (localStorage.getItem('data')), {
                                        method: 'GET',


                                        headers: {

                                            'Content-Type': 'application/json',
                                            'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                                            'token': localStorage.getItem('token')
                                        },


                                    })


                                        .then(res => res.json())

                                        .then((data) => {
                                            console.log(data)


                                            if (data.countries_list.length == 1) {

                                                this.setState({
                                                    drop: false,
                                                    phone: localStorage.getItem('data'),
                                                    canad: data,
                                                    city: data.countries_list[0].country_city_img,
                                                    def: data.countries_list[0].country_img_url,
                                                    contid: data.countries_list[0].id,
                                                    min: data.countries_list[0].minAccLen,
                                                    pref: data.countries_list[0].phonePrefix,
                                                    nata: "Enter the phone number to pay or choose the service below",
                                                    txt: data.countries_list[0].name,
                                                    // cates: "",
                                                    Loader: true,
                                                })






                                                fetch('https://mwapi.proc.one/api/content/get_all_categories?country_id=' + data.countries_list[0].id, {
                                                    method: 'GET',


                                                    headers: {

                                                        'Content-Type': 'application/json',
                                                        'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                                                        'token': localStorage.getItem('token')
                                                    },


                                                })


                                                    .then(res => res.json())

                                                    .then((data) => {
                                                        console.log(data)
                                                        this.setState({
                                                            allcatar: data.all_categories,
                                                            mainLoaders: true
                                                        })

                                                    })




                                                fetch('https://mwapi.proc.one/api/content/get_products?category_id&country_id=' + data.countries_list[0].id, {
                                                    method: 'GET',


                                                    headers: {

                                                        'Content-Type': 'application/json',
                                                        'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                                                        'token': localStorage.getItem('token')
                                                    },


                                                })


                                                    .then(res => res.json())

                                                    .then((data) => {
                                                        console.log(data)
                                                        this.setState({
                                                            monet: data.all_products_list,
                                                            // Loda: true,
                                                            Loader: true,
                                                        })

                                                    }
                                                    )




                                            }





                                        }
                                        )







                                }

                            }
                            )



                    }

                }
                )









        } else {
            fetch('https://mwapi.proc.one/api/content/get_all_categories', {
                method: 'GET',


                headers: {

                    'Content-Type': 'application/json',
                    'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                    'token': localStorage.getItem('token')
                },


            })


                .then(res => res.json())

                .then((data) => {
                    console.log(data)

                    if (data.error == 0) {


                        this.setState({
                            monet: data.all_products_list,
                            mainLoaders: true,

                            // cates: "",
                            Loader: true,
                        })

                        fetch('https://mwapi.proc.one/api/content/get_all_countries', {
                            method: 'GET',


                            headers: {

                                'Content-Type': 'application/json',
                                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                                'token': localStorage.getItem('token')
                            },


                        })


                            .then(res => res.json())

                            .then((data1) => {

                                console.log(data1)
                                data1.countries_list.sort((a, b) => {
                                    return a.name > b.name
                                })
                                if (data1.error == 0) {

                                    this.setState({
                                        isLoaded: true,
                                        nata: "Please choose the country from below list or enter the phone number to make the payment",
                                        canad: data1,
                                        allcatar: data.all_categories
                                    })
                                }

                            }
                            )



                    }

                }
                )
        }



    }
    dinam(obj) {
        this.setState({
            disLoad: false,
            cates: obj.category_name,
            Loda: false,
            Loadpay: false,
            lineload: false,
            productlogo: '',
            PayLoad: false,
            textprodec: ""
        })
        if (obj.category_name == "Mobile") {
            this.setState({
                cates: "Mobile",
                Loader: false,

                Loadpay: true,
                id: ""
            })
        } else if (obj.category_name == "All") {
            {
                fetch('https://mwapi.proc.one/api/content/get_products?category_id&country_id=' + this.state.contid, {
                    method: 'GET',


                    headers: {

                        'Content-Type': 'application/json',
                        'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                        'token': localStorage.getItem('token')
                    },


                })


                    .then(res => res.json())

                    .then((data) => {
                        console.log(data)
                        this.setState({
                            monet: data.all_products_list,
                            cates: obj.category_name,
                            Loader: true,
                            Loda: true,
                        })

                    }
                    )
                this.setState({
                    cates: obj.category_name
                })
            }
        } else {

            fetch('https://mwapi.proc.one/api/content/get_products?category_id=' + obj.id + '&country_id=' + this.state.contid, {
                method: 'GET',


                headers: {

                    'Content-Type': 'application/json',
                    'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                    'token': localStorage.getItem('token')
                },


            })


                .then(res => res.json())

                .then((data) => {
                    console.log(data)
                    this.setState({
                        monet: data.all_products_list,
                        cates: obj.category_name,
                        Loader: true,
                        Loda: true,
                    })

                }
                )
            // this.setState({
            //     cates: obj.category_name
            // })

        }
    }
    trackClick(e) {
        const myArray = e.target.className.split(" ");
        // console.log(myArray)
        if (myArray[0] != "gama") {
            this.setState({
                main: "",
                id: ""
            })
        }
        // console.log(e.target.className)

        // console.log("1111111111111111111111111111111111111111111111")
        if (e.target.className == "mymask" || e.target.className == "falgase") {
            // console.log("fsdjkfslfkjdsljkflsdf")
            this.setState({
                drop: true
            })
        } else {
            this.setState({
                drop: false
            })
        }
    }


    onPaste = value => {






    }



    handleOnChangePhoneInput = value => {
        console.log(value)
        const kk = "uz"

        this.setState({
            textprodec: ''
        })

        var financialGoal = (value.target.validity.valid) ? value.target.value : this.state.phone;
        var gg = value.target.value

        var re = new RegExp("ab+c");


        this.setState({
            Loadpay: false
        })
        var iner = false

        const main = this.state.canad.countries_list.filter(item =>
            item.name.toLowerCase().includes(financialGoal.toLowerCase())
        );

        if (main) {
            if (main.length == 1) {
                // financialGoal == main[0].phonePrefix
                financialGoal = main[0].phonePrefix
                iner = true
            }
        }


        if (!isNaN(financialGoal)) {
            // число
            iner = true
            console.log("число")
        } else {
            // буква
            console.log("Буквы")
            const operator = { countries_list: main }

            this.setState({
                canad: operator
            })

        }
        this.setState({ financialGoal });
        var test1 = "+998 xxx xxx xxx"
        // var lk = test1.replace(/[+] +/g, '')
        var lk = test1.replace(/[\s+.,%]/g, '')

        // console.log(test1.replace(/[^+\d]/g, ''))
        // console.log(test1.length)
        this.setState({
            maxa: lk.length,
            disLoad: false,
            id: ""
        })
        console.log(value.target.value.length);
        // this.setState({ phone: value.target.value }, () => {
        //     console.log(this.state.phone);
        // });

        // var derr = this.state.canad.countries_list[0].phoneMask
        // this.setState({
        //     textprodec: ""
        // })
        // var lk = derr.replace(/[\s+.,%]/g, '')
        // if (lk.length + 1 == value.target.value.length) {
        //     console.log("11111111111111111111")
        // } else {
        //     console.log("2222222222222222222")

        // }

        this.setState({
            phone: financialGoal,

        })



        fetch('https://mwapi.proc.one/api/content/get_country_by_prefix?prefix=' + financialGoal, {
            method: 'GET',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token': localStorage.getItem('token')
            },


        })


            .then(res => res.json())

            .then((data) => {


                if (iner == true) {


                    if (data.countries_list.length == 1) {

                        localStorage.setItem('data', data.countries_list[0].phonePrefix)
                        this.setState({
                            drop: false,
                            canad: data,
                            main: '',
                            min: data.countries_list[0].minAccLen,
                            city: data.countries_list[0].country_city_img,
                            def: data.countries_list[0].country_img_url,
                            contid: data.countries_list[0].id,
                            txt: data.countries_list[0].name,
                            pref: data.countries_list[0].phonePrefix,
                            nata: "Enter the phone number to pay or choose the service below",

                        })
                        fetch('https://mwapi.proc.one/api/content/get_all_categories?country_id=' + data.countries_list[0].id, {
                            method: 'GET',


                            headers: {

                                'Content-Type': 'application/json',
                                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                                'token': localStorage.getItem('token')
                            },


                        })


                            .then(res => res.json())

                            .then((data) => {
                                console.log(data)
                                this.setState({
                                    allcatar: data.all_categories,
                                    mainLoaders: true
                                })

                            })

                        if (lk.length == financialGoal.length) {

                        } else {
                            fetch('https://mwapi.proc.one/api/content/get_products?category_id&country_id=' + this.state.contid, {
                                method: 'GET',


                                headers: {

                                    'Content-Type': 'application/json',
                                    'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                                    'token': localStorage.getItem('token')
                                },


                            })


                                .then(res => res.json())

                                .then((data) => {
                                    console.log(data)
                                    this.setState({
                                        monet: data.all_products_list,
                                        cates: "",
                                        Loader: true,
                                    })

                                }
                                )

                        }




                    } else if (data.countries_list.length == 0) {
                        this.setState({
                            drop: false,

                        })
                    } else {

                        fetch('https://mwapi.proc.one/api/content/get_all_categories', {
                            method: 'GET',


                            headers: {

                                'Content-Type': 'application/json',
                                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                                'token': localStorage.getItem('token')
                            },


                        })


                            .then(res => res.json())

                            .then((data) => {
                                console.log(data)

                                if (data.error == 0) {


                                    this.setState({
                                        monet: data.all_products_list,
                                        cates: "",

                                        Loader: true,
                                        nata: "Please choose the country from below list or enter the phone number to make the payment",
                                        txt: "Select the country"
                                    })





                                }

                            }
                            )

                        this.setState({
                            canad: data,
                            drop: true,
                            city: "",
                            def: def
                        })
                    }
                }
            }
            )







    }

    contes = obj => {
        console.log(obj)
        this.setState({
            id: obj.id
        })
    }
    cont = obj => {
        console.log(obj)
        console.log(this.state.id)

        if (this.state.main == obj.main) {
            this.setState({
                id: "",
                prods: "",
                main: "",
            })
        } else {
            this.setState({
                id: obj.id,
                prods: obj.prod,
                main: obj.main,
                disLoad: true

            })
        }

    }
    buy() {
        this.setState({
            preload: true
        })


        if (this.state.cates == "Mobile") {
            fetch('https://mwapi.proc.one/api/customer/wallet/payment', {
                method: 'POST',


                headers: {

                    'Content-Type': 'application/json',
                    'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({

                    "phone_number": this.state.number,
                    "choosen_amount": this.state.id,
                    "country_id": this.state.contid
                })

            })


                .then(res => res.json())

                .then((data) => {
                    this.setState({
                        preload: false
                    })
                    console.log(data)
                    // this.props.mainPage.changeUnit(3)
                    if (data.statusId == 0) {
                        // alert(JSON.stringify(data))
                        this.setState({
                            PayLoad: true,
                            may: data
                        })

                    } else {
                        alert(data.reason)
                    }


                }
                )
        } else {
            var test = {
                "product_id": this.state.proc_id,
                "denomination_id": this.state.id,
                "country_id": this.state.contid
            }
            console.log(test)
            fetch('https://mwapi.proc.one/api/customer/wallet/payment', {
                method: 'POST',


                headers: {

                    'Content-Type': 'application/json',
                    'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                    'token': localStorage.getItem('token')
                },
                body: JSON.stringify({

                    "product_id": this.state.proc_id,
                    "denomination_id": this.state.main,

                })

            })


                .then(res => res.json())

                .then((data) => {
                    this.setState({
                        preload: false
                    })
                    console.log(data)
                    // this.props.mainPage.changeUnit(3)
                    if (data.statusId == 0) {
                        // alert(JSON.stringify(data))
                        this.setState({
                            PayLoad: true,
                            may: data
                        })

                    } else {

                        NotificationManager.error('Error message', data.reason, 5000, () => {

                        });
                        // alert(data.reason)
                    }


                }
                )
        }





    }



    render() {

        const { mainLoad, isLoaded } = this.state
        // console.log(this.state.catar)
        // console.log(this.state.dinaminat)
        if (!isLoaded) {
            return (
                <div className={"preloaders"}>
                    {/* <ImpulseSpinner className={"preloaders "} size={40} frontColor="black" backColor={"black"} /> */}
                    <TraceSpinner className={"preloaders "} size={110} frontColor="#8F1B3E" backColor={"black"} />
                </div>
            )
        } else {
            return (

                <div className='maincontroll'>
                    <NotificationContainer />
                    {(() => {

                        if (this.state.preload == false) {
                            return (
                                <div className={"nuless"}></div>
                            )
                        } else {
                            return (
                                <div className={"preloaders"}>
                                    {/* <ImpulseSpinner className={"preloaders "} size={40} frontColor="black" backColor={"black"} /> */}
                                    <TraceSpinner className={"preloaders "} size={110} frontColor="#8F1B3E" backColor={"gray"} />
                                </div>
                            )
                        }

                    })()}
                    <div className={"container "}>

                        <div className={"row "}>
                            <div className={"row_main col-lg-12 col-md-4 col-sm-6 col-6 "}>
                                {(() => {

                                    if (this.state.contid == "") {
                                        return (
                                            <p className={"countryPp"}>
                                                {this.state.txt}
                                            </p>
                                        )
                                    } else {
                                        return (
                                            <p className={"countryPp"}>
                                                {this.state.txt}
                                            </p>
                                        )
                                    }

                                })()}

                                {(() => {

                                    if (this.state.contid == "") {
                                        return (
                                            <p className={"gli"}>{this.state.nata}</p>
                                        )
                                    } else {
                                        return (
                                            <p className={"gli"}>{this.state.nata}</p>
                                        )
                                    }

                                })()}


                                <div className={"dropers"}>


                                    <form onSubmit={this.save}>
                                        <input value={this.state.phone} type="text"
                                            onChange={this.handleOnChangePhoneInput} onPaste={this.onPaste} className={"mymask"} placeholder={"Country code and phone number "}></input>
                                        <button className='btnsubs' type="submit">Submit</button>


                                        {(() => {
                                            if (this.state.phone.length >= this.state.min) {
                                                return (
                                                    <button onClick={this.save} className={"btnsave"}><img src={save} /></button>
                                                )

                                            } else {
                                                return (
                                                    <div className={"btnsave bbbttline"}><img src={save1} /></div>
                                                )
                                            }
                                        })()}

                                        {(() => {
                                            if (this.state.phone == "") {
                                                return (
                                                    <div className={"btnclear bbbttline"}><img src={clear1} /></div>
                                                )
                                            } else {
                                                return (
                                                    <button onClick={this.clear} className={"btnclear"}><img src={clear} /></button>
                                                )
                                            }
                                        })()}


                                    </form>




                                    <img className={"falgase"} src={this.state.def}></img>
                                    {(() => {
                                        if (this.state.drop == false) {

                                        } else {
                                            return (
                                                <div className={"listsete1"}></div>
                                            )
                                        }
                                    })()}

                                    {(() => {

                                        if (this.state.drop == false) {

                                        } else {
                                            return (
                                                <div className={"lisssssss"}>
                                                    {/* <div className={"subliste"}></div> */}

                                                    <div className={"listsete lenesert"}>


                                                        {this.state.canad.countries_list.map((col, index) => (

                                                            <button href={"#"} className={"achrones"}





                                                            >

                                                                <img className={"imgflagews"} src={col.country_img_url} />
                                                                <span className={"nameconet"}>{col.name}</span>
                                                                <span className={"prefixes"}>  (+{col.phonePrefix})</span>


                                                            </button>
                                                        ))}
                                                    </div>


                                                    <div className={"listsete"}>


                                                        {this.state.canad.countries_list.map((col, index) => (

                                                            <button href={"#"} className={"achrones"}


                                                                onClick={() =>
                                                                    this.canera({
                                                                        mask: col.phonePrefix,
                                                                        img: col.country_img_url,
                                                                        txt: col.name

                                                                    })
                                                                }



                                                            >

                                                                <img className={"imgflagews"} src={col.country_img_url} />
                                                                <span className={"nameconet"}>{col.name}</span>
                                                                <span className={"prefixes"}>  (+{col.phonePrefix})</span>


                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            )
                                        }

                                    })()}
                                    {(() => {


                                        if (this.state.errostext.length > 0) {
                                            return (
                                                <p className={"redes"}>{this.state.errostext}</p>
                                            )
                                        }


                                        if (this.state.phone.length > this.state.maxa) {
                                            return (

                                                <p className={"redes"}>Phone number is incomplete</p>
                                            )

                                        } else {
                                            return (
                                                <p className={"redes"}></p>
                                            )
                                        }
                                    })()}
                                </div>
                                {/* <PhoneInput className={"mymask"}
                                    name="phoneNumber"
                                    type="text"

                                    inputProps={{
                                        name: "phone",
                                        country: "us",
                                        required: true,
                                        autoFocus: true
                                    }}
                                    value={this.state.phone}
                                    onChange={this.handleOnChangePhoneInput} /> */}



                            </div>
                        </div>
                    </div>



                    <div className={"bg_bus"}>
                        <div className={"container conterespaddtops"}>
                            <div className={"row"}>
                                <div className={"canfloor222 col-lg-12 col-md-4 col-sm-6 col-6 "}>

                                    {/* {console.log(this.state.allcatar)} */}


                                    <div className={"relav"}>
                                        {
                                            this.state.mainLoaders == true && (


                                                <div className={"centeralignsye"}>
                                                    
                                                    {this.state.allcatar.map((col, index) => (
                                                        <div className={"rerow"}>
                                                                   

                                                            {(() => {
                                                                if (col.category_name == "All" ||  col.category_name == "Mobile" || col.product_list.length > 0) {
                                                                  

                                                                    if (this.state.cates == col.category_name) {
                                                                        return (
                                                                            <button



                                                                                className={"catname1"}>

                                                                                {col.category_name}
                                                                            </button>
                                                                        )

                                                                    } else {
                                                                       
                                                                            return (
                                                                                <button onClick={() =>
                                                                                    this.dinam({
                                                                                        id: col.id,
                                                                                        category_name: col.category_name
                                                                                    })
                                                                                } className={"catname"}>


                                                                                    {col.category_name}




                                                                                </button>

                                                                            )
                                                                        

                                                                    }
                                                                }

                                                                 
                                                            })()}


                                                        </div>





                                                    ))}

                                                </div>
                                            )
                                        }

                                        {console.log(this.state.monet)}


                                        {(() => {

                                            if (!this.state.Loda) {

                                            } else {
                                                return (
                                                    <div className={"consoles"}>
                                                        {this.state.monet.map((col, index) => (

                                                            <div className='borderbts'>
                                                                {
                                                                    this.state.textprodec == col.product_name && (
                                                                        <button className='btnproductenter btnmycenter '
                                                                            // onClick={() =>
                                                                            //     this.dia({
                                                                            //         id: col.proc_id,
                                                                            //         textprodec: col.product_name,
                                                                            //         productlogo: col.product_img
                                                                            //     })
                                                                            // }
                                                                            href={"#"}><img className={"improducer"} src={col.product_img}></img></button>
                                                                    )
                                                                }
                                                                {
                                                                    this.state.textprodec != col.product_name && this.state.textprodec.length > 0 && (
                                                                        <button className='btnproductenter btnmycenter  deactivebtncenter'
                                                                            onClick={() =>
                                                                                this.dia({
                                                                                    id: col.proc_id,
                                                                                    textprodec: col.product_name,
                                                                                    productlogo: col.product_img
                                                                                })
                                                                            }
                                                                            href={"#"}><img className={"improducer"} src={col.product_img}></img></button>
                                                                    )
                                                                }
                                                                {
                                                                    this.state.textprodec.length == 0 && (
                                                                        <button className='btnproductenter btnmycenter '
                                                                            onClick={() =>
                                                                                this.dia({
                                                                                    id: col.proc_id,
                                                                                    textprodec: col.product_name,
                                                                                    productlogo: col.product_img
                                                                                })
                                                                            }
                                                                            href={"#"}><img className={"improducer"} src={col.product_img}></img></button>
                                                                    )
                                                                }

                                                            </div>


                                                        ))}
                                                    </div>
                                                )
                                            }



                                        })()}
                                           
                                        {(() => {
                                            // console.log(this.state.cates)
                                            if (this.state.lineload && this.state.cates != "Mobile") {
                                                return (
                                                    <div className='lineopt'></div>
                                                )
                                            } else  {
                                                return (
                                                    <div className='lineopt2222'></div>
                                                )
                                            }


                                        })()}





                                        <div className={"container "}>
                                            <div className={"row "}>
                                                <div className={" col-lg-6 col-md-4 col-sm-6 col-6 "}>
                                                    {
                                                        this.state.textprodec.length > 0 && (


                                                            <div>

                                                                <p className='textprodec'>{this.state.textprodec}</p>
                                                                <p className='textprodec'>{this.state.txt}</p>

                                                            </div>
                                                        )
                                                    }
                                                </div>

                                            </div></div>



                                        <div className={"container "}>



                                            {(() => {
                                                if (this.state.cates == "Mobile") {
                                                    return (
                                                        <div className={"row "}>
                                                            <div className={" col-lg-6 col-md-4 col-sm-6 col-6 "}>
                                                                <p className={"operat"}>{this.state.trans.operatorShort}</p>
                                                                <p className={"operat"}>{this.state.trans.countryShort}</p>
                                                            </div>
                                                            <div className={"imoprs col-lg-6 col-md-4 col-sm-6 col-6 "}>
                                                                <img className='opersurl' src={this.state.opersurl} />
                                                            </div>
                                                        </div>
                                                    )
                                                } else {

                                                }
                                            })()}


                                        </div>


                                        {(() => {

                                        })()}






                                        {(() => {
                                            if (this.state.PayLoad == true) {
                                                return (
                                                    <div className={"wareappers"}>

                                                        <div className={"maintiperese"}>
                                                            <div className={"topbarch"}> Successful transaction</div>

                                                            <div className={"tableplashk"}>

                                                                <p className={"tabmainn"}><div className={"taleft"}> Date:   </div>   <div className={"taright"}>{this.state.may.timeStart}</div></p>
                                                                <div className={"linenewr12"}> </div>
                                                                <p className={"tabmainn"}><div className={"taleft"}>  Time:  </div>    <div className={"taright"}>{this.state.may.timeStart}</div></p>
                                                                <div className={"linenewr12"}> </div>
                                                                <p className={"tabmainn"}><div className={"taleft"}>  Operator: </div>     <div className={"taright"}>{this.state.may.operatorName}</div></p>
                                                                <div className={"linenewr12"}> </div>
                                                                <p className={"tabmainn"}><div className={"taleft"}>  Country:  </div>    <div className={"taright"}>{this.state.may.country}</div></p>
                                                                <div className={"linenewr12"}> </div>
                                                                <p className={"tabmainn"}><div className={"taleft"}> Account:  </div>    <div className={"taright"}>{this.state.may.id}</div></p>
                                                                <div className={"linenewr12"}> </div>
                                                                <p className={"tabmainn"}><div className={"taleft"}> Product:   </div>   <div className={"taright"}>{this.state.may.amount} QAR</div></p>
                                                            </div>


                                                        </div>
                                                        <div className={"gruppadbtn"}>

                                                            <button className={"cash"}><img src={cash} /></button>
                                                            <button className={"cash"}><img src={print} /></button>
                                                            <button onClick={this.repeat} className={"cash"}><img src={repeat} /></button>
                                                            <button onClick={this.home} className={"homeepaggg"}><img src={home} /></button>

                                                        </div>





                                                    </div>
                                                )
                                            } else {
                                                return (


                                                    <div className={"disl"}>

                                                        <div className={"cont_recivers"}>
                                                            {(() => {

                                                                if (this.state.cates == "Mobile") {

                                                                    return (
                                                                        <div className={"cont_recivers"}>
                                                                            {/* {console.log("fsdfsdf")} */}
                                                                            {this.state.dinaminat.map((col, index) => (

                                                                                <div className={"recoress"}>

                                                                                    {
                                                                                        this.state.main == col.denomination && (
                                                                                            <button onClick={() =>
                                                                                                this.cont({ main: col.denomination, id: col.denomination })
                                                                                            } className={"buys buyactive"}>
                                                                                                <p className={"dina"}>{col.denomination}</p>

                                                                                                <p className="reci">QAR</p>
                                                                                                <p className="curen">{parseInt(col.receive)} {this.state.currency}</p>
                                                                                            </button>
                                                                                        )
                                                                                    }


                                                                                    {
                                                                                        this.state.main != col.denomination && (
                                                                                            <button onClick={() =>
                                                                                                this.cont({ main: col.denomination, id: col.denomination })
                                                                                            } className={"buys "}>
                                                                                                <p className={"gama dina"}>{col.denomination}</p>

                                                                                                <p className="gama reci">QAR</p>
                                                                                                <p className="gama curen">{parseInt(col.receive)} {this.state.currency}</p>
                                                                                            </button>
                                                                                        )
                                                                                    }





                                                                                </div>


                                                                            ))}



                                                                        </div>
                                                                    )
                                                                } else {
                                                                    if (!this.state.disLoad) {

                                                                    } else {

                                                                        return (

                                                                            <div className={"cont_recivers"}>
                                                                                {this.state.dimas.map((col, index) => (


                                                                                    <span  >

                                                                                        {
                                                                                            this.state.main == col.id && (
                                                                                                <button onClick={() =>
                                                                                                    this.cont({
                                                                                                        main: col.id,
                                                                                                        prod: col.productId,

                                                                                                    })
                                                                                                } id="ones" className={"buys buyactive"}>
                                                                                                    <p className={"gama dina"}>{col.denomination}</p>

                                                                                                    <p className="gama reci">QAR</p>
                                                                                                    <div onClick={() =>
                                                                                                    this.cont({
                                                                                                        main: col.id,
                                                                                                        prod: col.productId,

                                                                                                    })
                                                                                                }  className="gama curen">{parseInt(col.receive)} {col.currency}</div>
                                                                                                </button>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            this.state.main != col.id && (
                                                                                                <button onClick={() =>
                                                                                                    this.cont({
                                                                                                        main: col.id,
                                                                                                        prod: col.productId,

                                                                                                    })
                                                                                                } className={"buys"}>
                                                                                                    <p className={"gama dina"}>{col.denomination}</p>

                                                                                                    <p className="gama reci">QAR</p>
                                                                                                    <div onClick={() =>
                                                                                                    this.cont({
                                                                                                        main: col.id,
                                                                                                        prod: col.productId,

                                                                                                    })
                                                                                                } className="gama curen">{parseInt(col.receive)} {col.currency}</div>
                                                                                                </button>
                                                                                            )
                                                                                        }
                                                                                    </span>




                                                                                ))}





                                                                            </div>
                                                                        )



                                                                    }
                                                                }

                                                            })()}





                                                        </div>

                                                        {(() => {

                                                            if (!this.state.Loadpay) {

                                                            } else {
                                                                if (this.state.id == "") {
                                                                    return (

                                                                        <button className={"pay2"} >Pay</button>
                                                                    )
                                                                } else {
                                                                    return (

                                                                        <button className={"pay"} onClick={this.buy}>Pay</button>
                                                                    )
                                                                }
                                                            }

                                                        })()}
                                                    </div>

                                                )
                                            }
                                        })()}

                                    </div>






                                </div>
                            </div>
                        </div>
                        {/* <img  src={silc}/> */}
                        {
                            this.state.city.length > 0 && (
                                <div className='alteranr'>
                                    {(() => {
                                        var ff = this.state.city.split('.')
                                        console.log(ff)
                                        console.log(ff[ff.length - 1])
                                        if ('svg' != ff[ff.length - 1]) {

                                        } else {
                                            return (
                                                <img className={"imglondos"} src={this.state.city} />
                                            )
                                        }
                                    })()}


                                </div>
                            )
                        }


                    </div>


                    <div className={"massrelav"}>

                    </div>
                </div>
            )
        }

    }
}
export default Main;