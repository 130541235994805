

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './img/logo.svg'
import exit from './img/exit.svg'
import Profile from './img/Profile.svg'
// import bg from './img/bg.svg'
import biglogo from './img/biglogo.svg'
import {

    Navbar,
    Container,
    Nav,
    NavDropdown

} from 'react-bootstrap';
import { Link } from 'react-router-dom';
class Auth extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
       email:"",
       pass: "",
       text : "",
       main : false
               }
               this.email = this.email.bind(this);
               this.pass = this.pass.bind(this);
               this.log = this.log.bind(this);
        
               

    }

 
    

    email(e){
        this.setState({
            email:e.target.value
        })
        console.log(e.target.value)
    }
    pass(e){
        this.setState({
            pass:e.target.value
        })
        // console.log(e.target.value)
    }

    componentDidMount() {
       

        if(localStorage.getItem('token')){
            if(localStorage.getItem('token').length > 0 ){
                window.location.href = "/"
            }else{
      
            }
      
          }else{
           
          if(window.location.pathname == "/"){
            
         
          }else{
            // document.location.replace("/auth");
          }
      
          }


    }
    log(){
        fetch('https://mwapi.proc.one/api/acc_login', {
            method: 'POST',


            headers: {
               
                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
           
            },
            body: JSON.stringify({

                "email": this.state.email,
                "password": this.state.pass
             
            })

        })


            .then(res => res.json())

            .then((data) => {
                    console.log(data)
           
if(data.error == 0){
    localStorage.setItem('token', data.customer.token)
    window.location.href = "/"
    this.setState({
        text: data.reason,
        main: true
    })
}else{
    this.setState({
        text: data.reason,
        main: true
    })
}

                }
            )
    }
    
    render() {
     return(
 <div  className={""}>
<div className={"au_main"}>

<a href={"/"}>    
<img src={biglogo}></img></a>
<p className={"welcomess"}>Welcome back!</p>
<p className={"contin"}>Login to continue</p>
<div className={"inl"}><input onChange={this.email} placeholder={"Email"}></input>
</div>
<div className={"inl"}><input type={"password"} onChange={this.pass} placeholder={"Password"}></input>
</div>
{(() => {  
    if(this.state.main == false){

    }else{
        return(
            <p className={"wrongersp"}>
                {this.state.text}
            </p>
        )
    }
     })()}
<div className={"rega_blo"}>
<button  onClick={this.log} className={"ffff1331 reg_l"}>Login</button>
<a  className={"reg_r ffff11"} href={"/create"}>Create account</a>

</div>


<a href={"/reset"} className={"forgot "}>Forgot your password</a>
</div>
<div className={"absol_main"}>

</div>
 </div>
     )  
  }
}
export default Auth;