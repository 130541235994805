import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import Main from './Main';
import Header from './Header';
import Footer from './Footer';
import Auth from './Auth';
import Create from './Create';
import Profile from './Profile';
import Reset from './Reset';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


class App extends Component {
  constructor(props) {
    super(props)


  }




  componentDidMount() {
    console.log(window.location.pathname)





    if (localStorage.getItem('token')) {
      if (localStorage.getItem('token').length > 0) {

      } else {

      }

    } else {

      if (window.location.pathname == "/") {
        window.location.href = "/auth"

      } else {
        // document.location.replace("/auth");
      }

    }
  }

  render() {

    return (
      <div id={"App"} className="App ">
        {(() => {

          if (localStorage.getItem('token')) {
            if (localStorage.getItem('token').length > 0) {
              return (
                <Header></Header>
              )
            } else {

            }
          } else {

          }

        })()}


{(() => {

if (localStorage.getItem('token')) {
  if (localStorage.getItem('token').length > 0) {
    return (
      <div id={"md"} className={"App22 minhg"}>



      <Router>
        <Switch>


          <Route exact path="/" component={Main} />
          <Route path="/profile" component={Profile} />
          <Route exact path="/auth" component={Auth} />
          <Route path="/create" component={Create} />
          <Route path="/reset" component={Reset} />

        </Switch>
      </Router>
    </div>

    )
  } else {
  
  }
} else {
  return(
    <div id={"md"} className={"App44 "}>



    <Router>
      <Switch>


        <Route exact path="/" component={Main} />
        <Route path="/profile" component={Profile} />
        <Route exact path="/auth" component={Auth} />
        <Route path="/create" component={Create} />
        <Route path="/reset" component={Reset} />

      </Switch>
    </Router>
  </div>

  )
}

})()}
      

        {(() => {

          if (localStorage.getItem('token')) {
            if (localStorage.getItem('token').length > 0) {
              return (
                <Footer />
              )
            } else {

            }
          } else {

          }

        })()}





      </div>
    )
  }
}
export default App;
