import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './img/logo.svg'
import exit from './img/exit.svg'

// import bg from './img/bg.svg'
import biglogo from './img/biglogo.svg'
import {

    Navbar,
    Container,
    Nav,
    NavDropdown

} from 'react-bootstrap';
import { Link } from 'react-router-dom';
class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            pass: "",
            text: "",
            firstname:"",
            lastname:"",
            address:"",
            city:"",
            states:"",
            countryname:"",
            customer:"",
            main: false
        }
        this.email = this.email.bind(this);
  
        this.log = this.log.bind(this);
        this.save = this.save.bind(this);
        this.firstname = this.firstname.bind(this);
        this.lastname = this.lastname.bind(this);
        this.address = this.address.bind(this);
        this.city = this.city.bind(this);

        this.states = this.states.bind(this);
        this.countryname = this.countryname.bind(this);
    }


save(){
    const main = {
        "first_name": this.state.firstname, 
                "last_name":this.state.lastname,
                 "address":this.state.address,
                  "state":this.state.states,
                "country_id":"333",
                 "city":this.state.city
    }
    console.log(main)
    fetch('https://mwapi.proc.one/api/customer/profile_update', {
            method: 'POST',


            headers: {
               
                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token': localStorage.getItem('token')
           
            },
            body: JSON.stringify({
                "first_name": this.state.firstname, 
                "last_name":this.state.lastname,
                 "address":this.state.address,
                  "state":this.state.states,
                  "country_id":"612e437b226e854d516e172f",
                 "city":this.state.city
             
            })

        })


            .then(res => res.json())

            .then((data) => {
                    console.log(data)
   
alert(JSON.stringify(data.customer))
                }
            )
}
    email(e){
this.setState({
    email:e.target.value
})
    }
    firstname(e){
        console.log(e.target.value)
        this.setState({
            firstname:e.target.value
        })
    }
    lastname(e){
        this.setState({
            lastname:e.target.value
        })
    }
    address(e){
        this.setState({
            address:e.target.value
        })
    }
    city(e){
        this.setState({
            city:e.target.value
        })
    }
    states(e){
        this.setState({
            states:e.target.value
        })
    }
    countryname(e){
        this.setState({
            countryname:e.target.value
        })
    }
    componentDidMount() {
        

        fetch('https://mwapi.proc.one/api/get_customer_profile', {
            method: 'POST',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
                'token' : localStorage.getItem('token')

            },
            body: JSON.stringify({


            })

        })


            .then(res => res.json())

            .then((data) => {
                console.log(data)

                if(data.error == 0){
                 this.setState({
                    address: data.customer.address,
                    city: data.customer.city,
                    firstname: data.customer.first_name,
                    lastname: data.customer.last_name,
                    states: data.customer.state,
                 })
                }else if(data.error == 403){
                    localStorage.removeItem('token');
                    window.location.href = "/auth"
                }

            }
            )

        var elem = document.getElementById("md");
    console.log(elem.classList)
    elem.classList.remove("App22"); 
    var elem2 = document.getElementById("md");
    elem2.style.backgroundColor = "#E2E2E2"
   
      
  console.log(elem)


    }
    log() {
        fetch('https://mwapi.proc.one/api/acc_login', {
            method: 'POST',


            headers: {

                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",

            },
            body: JSON.stringify({

                "email": this.state.email,
                "password": this.state.pass

            })

        })


            .then(res => res.json())

            .then((data) => {
                console.log(data)

                if (data.error == 0) {
                    localStorage.setItem('token', data.customer.token)
                    window.location.href = "/"
                    this.setState({
                        text: data.reason,
                        main: true
                    })
                } else {
                    this.setState({
                        text: data.reason,
                        main: true
                    })
                }

            }
            )
    }

    render() {
        return (
            <div className={"container "}>
                <div className={"row"}>


                    <p className={"titlemain"}>User information</p>
                    {/* <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span className={"textleft"}>Email *</span>

                            <input onChange={this.email} className={"textright"}></input>
                        </div>
                    </div> */}


                    <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span className={"textleft"}>Password *</span>

                            <input  className={"textright"} ></input>
                        </div>
                    </div>
                    <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span className={"textleft"}>Confirm password *</span>

                            <input className={"textright"} ></input>
                        </div>
                    </div>
                
<div className={"linebot"}></div>








<p className={"titlemain"}>Contact information</p>
                    <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span className={"textleft"}>First Name *</span>

                            <input value={this.state.firstname} onChange={this.firstname} className={"textright"} ></input>
                        </div>
                    </div>


                    <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span  className={"textleft"}>Last Name *</span>

                            <input value={this.state.lastname} onChange={this.lastname} className={"textright"} ></input>
                        </div>
                    </div>
                    <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span  className={"textleft"}>Phone Number *</span>

                            <input  className={"textright"}></input>
                        </div>
                    </div>
                    
<div className={"linebot"}></div>















<p className={"titlemain"}>Address Information</p>
                    <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span className={"textleft"}>Address *</span>

                            <input value={this.state.address} onChange={this.address} className={"textright"} ></input>
                        </div>
                    </div>


                    <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span className={"textleft"}>City *</span>

                            <input value={this.state.city} onChange={this.city} className={"textright"}></input>
                        </div>
                    </div>
                    <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span className={"textleft"}>State *</span>

                            <input onChange={this.states} value={this.state.states} className={"textright"} ></input>
                        </div>
                    </div>
                   

                    <div className={"col-lg-10 col-md-4 col-sm-6 col-6  maingl"}>
                        <div className={"groupmodal"}>
                            <span className={"textleft"}>Country</span>

                            <input onChange={this.countryname} className={"textright"} ></input>
                        </div>
                    </div>

                </div>
                <button onClick={this.save} className={"save"}>Save</button>
            </div>

        )
    }
}
export default Profile;