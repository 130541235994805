

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './img/logo.svg'
import exit from './img/exit.svg'

import up from './img/up.svg'

import down from './img/down.svg'
import Profile from './img/Profile.svg'
import {

  Navbar,
  Container,
  Nav,
  NavDropdown

} from 'react-bootstrap';
import { Link } from 'react-router-dom';
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      main1: "",
      main2: "",
      main3: "",
      profile: "",
      isLoaded: false
    }

  }


exit(){

  if(localStorage.getItem('token')){
    if(localStorage.getItem('token').length > 0 ){
localStorage.removeItem('token')
window.location.href = "/auth"
    }else{

    }

  }else{
    
  if(window.location.pathname == "/"){
  
 
  }else{
    // document.location.replace("/auth");
  }

  }
}

  componentDidMount() {

    fetch('https://mwapi.proc.one/api/get_customer_profile', {
      method: 'POST',


      headers: {

        'Content-Type': 'application/json',
        'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
        'token': localStorage.getItem('token')
      },


    })


      .then(res => res.json())

      .then((data) => {
        console.log(data)
        if (data.error == 0) {
          this.setState({
            profile: data.customer,
            isLoaded: true
          })
        } else {
          localStorage.removeItem('token');
          window.location.href = "/auth"
        }
      }
      )


  }

  render() {
    const { isLoaded } = this.state
    console.log(this.state.main)
    if (!isLoaded) {
      return (
        <p></p>
      )
    } else {
      return (
        <div className={"disp_center"}>
          <Navbar className="justify-content-center" expand="lg">
            <Container>
              <Navbar.Brand href="/">
                <img
                  alt=""
                  src={logo}

                  className="d-inline-block align-top"
                />{' '}

              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">

                  {(() => {

                    if (this.state.main1 == "wallet") {
                      return (<Nav.Link className={"padd_rgg busettings"} onClick={() => {
                        this.setState({
                          main1: ""
                        })
                      }} >Wallet <img className={"upclasters"} src={down} />    <a className={"botomheadtextbtn"}>{this.state.profile.walet}</a></Nav.Link>


                      )
                    } else {
                      return (
                        <Nav.Link className={"padd_rgg busettings"} onClick={() => {
                          this.setState({
                            main1: "wallet"
                          })
                        }}>Wallet <img src={down} /></Nav.Link>
                      )
                    }

                  })()}




                  {(() => {

                    if (this.state.main2 == "balance") {
                      return (

                        <Nav.Link onClick={() => {
                          this.setState({
                            main2: ""
                          })
                        }} className={"padd_rgg busettings"} ><span className={"flleft"}>Balance</span><img className={"upclasters"} src={down} />   <a className={"botomheadtextbtn"}>QAR 
                        {this.state.profile.walet_amount ==0 &&(<span>  0.00</span>) } 
                        {this.state.profile.walet_amount >0 &&(<span>  {this.state.profile.walet_amount.toFixed(2)}</span>) } 
                        </a></Nav.Link>


                      )
                    } else {
                      return (
                        <Nav.Link className={"padd_rgg busettings"} onClick={() => {
                          this.setState({
                            main2: "balance"
                          })
                        }}>Balance <img src={down} /></Nav.Link>
                      )
                    }

                  })()}



                  {(() => {

                    if (this.state.main3 == "cashback") {
                      return (

                        <Nav.Link onClick={() => {
                          this.setState({
                            main3: ""
                          })
                        }} className={"padd_rgg busettings"} ><span className={"flleft"}>Cashback</span><img src={down} className={"upclasters"}/>   
                        <a className={"botomheadtextbtn"}>QAR {this.state.profile.walet_cache_back == 0&&(<span>0.00</span>) } 
                        {this.state.profile.walet_cache_back > 0&&(<span>{this.state.profile.walet_cache_back.toFixed(2)}</span>) } 
                        </a></Nav.Link>


                      )
                    } else {
                      return (
                        <Nav.Link className={"padd_rgg busettings"} onClick={() => {
                          this.setState({
                            main3: "cashback"
                          })
                        }}>Cashback <img src={down} /></Nav.Link>
                      )
                    }


                  })()}






                  {(() => {

                    if (this.state.main == "profile") {
                      return (


                        <Nav.Link onClick={() => {
                          this.setState({
                            main: "profile"
                          })
                        }} className={"profile_head hd_none busettings"} >
                 
                          Profile </Nav.Link>

                      )
                    } else {
                      return (

                        <Nav.Link onClick={() => {
                          this.setState({
                            main: "profile"
                          })
                        }} className={"profile_head hd_none busettings"} >
                       
                          Profile </Nav.Link>
                      )
                    }





                  })()}


<Nav.Link onClick={() => {
                         this.exit()
                        }} className={"hd_none mycoonbnnl busettings"} href="">
                          <img src={exit}></img>
                           </Nav.Link>

                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      )
    }

  }
}
export default Header;