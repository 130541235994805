

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './img/logo.svg'
import exit from './img/exit.svg'
import Profile from './img/Profile.svg'
// import bg from './img/bg.svg'
import biglogo from './img/biglogo.svg'
import {

    Navbar,
    Container,
    Nav,
    NavDropdown

} from 'react-bootstrap';
import { Link } from 'react-router-dom';
class Reset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            pass: "",
            text: "",
            main: false
        }
        this.email = this.email.bind(this);
        this.pass = this.pass.bind(this);
        this.log = this.log.bind(this);



    }




    email(e) {
        this.setState({
            email: e.target.value
        })
        console.log(e.target.value)
    }
    pass(e) {
        this.setState({
            pass: e.target.value
        })
        // console.log(e.target.value)
    }

    componentDidMount() {

        if(localStorage.getItem('token')){
            if(localStorage.getItem('token').length > 0 ){
                window.location.href = "/"
            }else{
      
            }
      
          }else{
           
          if(window.location.pathname == "/"){
            
         
          }else{
            // document.location.replace("/auth");
          }
      
          }


    }
    log(){
        fetch('https://mwapi.proc.one/api/change_password', {
            method: 'POST',


            headers: {
               
                'Content-Type': 'application/json',
                'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
           
            },
            body: JSON.stringify({

                "email": this.state.email,
                
             
            })

        })


            .then(res => res.json())

            .then((data) => {
                    console.log(data)
           
if(data.error == 0){
  
    this.setState({
        text: data.reason,
        main: true
    })
}else{
    this.setState({
        text: data.reason,
        main: true
    })
}

                }
            )
    }
    

    render() {
        return (
            <div className={""}>
                <div className={"au_main"}>


                   <a href={"/"}> <img src={biglogo}></img></a>
                    <p className={"welcomess"}>Reset password</p>
                    <p className={"contin"}>Enter your registered email and receive message to reset the password</p>
                    <div className={"inl"}><input onChange={this.email} placeholder={"Email"}></input>
                    </div>

                    {(() => {
                        if (this.state.main == false) {

                        } else {
                            return (
                                <p className={"wrongersp"}>
                                    {this.state.text}
                                </p>
                            )
                        }
                    })()}
                    <div className={"rega_blo"}>
                        <button onClick={this.log} className={"reg_l"}>Send</button>


                    </div>


                    <a href={"/auth"} className={"forgot"}>Back to login</a>
                </div>
                <div className={"absol_main"}>

                </div>
            </div>
        )
    }
}
export default Reset;