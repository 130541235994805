

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './img/logo.svg'
import exit from './img/exit.svg'
import Profile from './img/Profile.svg'
import {

    Navbar,
    Container,
    Nav,
    NavDropdown

} from 'react-bootstrap';
import { Link } from 'react-router-dom';
class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
     
        }
  
    }

 
   

    componentDidMount() {
       



    }
    
    render() {
     return(
 <div className={"footer"}>

<div className={"container  cs_cont_main_unkn"}>


<div className={"row"}>
<div className={"nanacuber col-lg-2 col-md-4 col-sm-6 col-6 "}>
  
</div>

    <div className={"nanacuber col-lg-2 col-md-4 col-sm-6 col-6 "}>
    Mitra
</div>
<div className={"nanacuber col-lg-2 col-md-4 col-sm-6 col-6 "}>
Contacts
</div>
<div className={"nanacuber col-lg-2 col-md-4 col-sm-6 col-6 "}>
Bank
</div>
<div className={"nanacuber col-lg-2 col-md-4 col-sm-6 col-6 "}>
T&C
</div>

<div className={"nanacuber col-lg-2 col-md-4 col-sm-6 col-6 "}>
  
</div>

</div>
</div>
 </div>
     )  
  }
}
export default Footer;